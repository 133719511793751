import React from "react";

type Props = {
  school: { index: string; name: string; url: string };
};

const School = ({ school }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">School:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>
          {school.name} ({school.index})
        </p>
      </div>
    </div>
  );
};

export default School;
