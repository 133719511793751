import React from "react";

type Props = { isRitual: boolean };

const Ritual = ({ isRitual }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Ritual:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{isRitual ? "Yes" : "No"}</p>
      </div>
    </div>
  );
};

export default Ritual;
