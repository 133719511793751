import { Link } from "react-router-dom";
import { ISpell } from "../interface/SpellListInterface";
import { useEffect, useState } from "react";

type SpellCardProps = {
  spell: ISpell;
};

const SpellCard = ({ spell }: SpellCardProps) => {
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    const savedSpells = JSON.parse(localStorage.getItem("savedSpells") || "[]");
    if (savedSpells.includes(spell.index)) {
      setSaved(true);
    } else {
      setSaved(false);
    }
  }, [spell.index]);

  const handleSave = () => {
    let savedSpells = JSON.parse(localStorage.getItem("savedSpells") || "[]");
    if (saved) {
      savedSpells = savedSpells.filter(
        (index: string) => index !== spell.index
      );
    } else {
      savedSpells.push(spell.index);
    }
    localStorage.setItem("savedSpells", JSON.stringify(savedSpells));
    setSaved(!saved);
  };

  return (
    <div className="bg-white shadow-md rounded-lg px-8 py-4 max-w-sm min-w-56 hover:scale-[1.08] hover:bg-slate-200 transition-all">
      <h2 className="text-2xl font-bold mb-2">{spell.name}</h2>
      <p className="text-gray-700 text-base mb-4">{spell.index}</p>
      <div className="flex items-center w-fill justify-evenly">
        <Link
          to={`/spell/${spell.index}`}
          className="bg-blue-500 text-white font-semibold px-4 py-2 rounded hover:bg-blue-700"
        >
          Visit
        </Link>
        <button
          className={`px-4 py-2 border border-gray-300  rounded transition-all duration-200 ${
            saved
              ? "bg-blue-500 text-white border-blue-500 hover:bg-blue-600"
              : "bg-white text-gray-800 hover:bg-gray-100"
          }`}
          onClick={handleSave}
        >
          {saved ? "Saved" : "Save"}
        </button>
      </div>
    </div>
  );
};

export default SpellCard;
