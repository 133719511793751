type Props = {
  subClasses: {
    index: string;
    name: string;
    url: string;
  }[];
};

const SubClasses = ({ subClasses }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Subclasses:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <ul>
          {subClasses.map((c, index) => (
            <li key={index}>
              {c.name} ({c.index})
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubClasses;
