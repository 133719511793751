import React from "react";

type Props = {
  damage: {
    damage_type?: {
      index: string;
      name: string;
      url: string;
    };
    damage_at_slot_level?: {
      [key: string]: string;
    };
  };
};

const Damage = ({ damage }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Damage:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        {damage?.damage_type && (
          <>
            <p>
              Type: {damage?.damage_type.name} ({damage?.damage_type.index})
            </p>
          </>
        )}
        {damage?.damage_at_slot_level && (
          <>
            <p>
              Slot Level:{" "}
              {Object.entries(damage?.damage_at_slot_level).map(
                ([key, value]) => (
                  <p key={key}>
                    {key}: {value}
                  </p>
                )
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Damage;
