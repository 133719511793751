import axios from 'axios';
import endpoints from '../constants/endpoints';



const fetchSpellsHandler = async () => {
    try {
        const response = await axios.get(endpoints.spells);
        return response.data;
    } catch (error) {
        throw error
    }
};

export { fetchSpellsHandler };