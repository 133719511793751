import React from "react";

type Props = {
  error: any;
};

const ErrorMessage = ({ error }: Props) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white shadow-md rounded-lg p-8 flex flex-col gap-4 items-center">
        <div>
          <h2 className="text-2xl font-bold mb-4 text-center">Error:</h2>
          <p className="text-center">{error.message}</p>
        </div>
        <button
          className="bg-blue-500 text-white font-semibold px-4 py-2 rounded hover:bg-blue-700"
          onClick={() => window.location.reload()}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

export default ErrorMessage;
