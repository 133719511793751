import React from "react";

type Props = { range: string };

const Range = ({ range }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Range:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{range}</p>
      </div>
    </div>
  );
};

export default Range;
