
type Props = {};

const CircularLoading = (props: Props) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div
        className="spinner-border animate-spin inline-block w-12 h-12 border-[6px] border-gray-400 border-t-blue-500 rounded-full"
        role="status"
      ></div>
    </div>
  );
};

export default CircularLoading;
