import React from "react";

type Props = {
  attack_type: string;
};

const AttackType = ({ attack_type }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Attack Type:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{attack_type}</p>
      </div>
    </div>
  );
};

export default AttackType;
