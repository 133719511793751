import React from "react";

type Props = {
  duration: string;
};

const Duration = ({ duration }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Duration:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{duration}</p>
      </div>
    </div>
  );
};

export default Duration;
