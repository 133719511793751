import React from "react";

type Props = {
  components: string[];
};

const Components = ({ components }: Props) => {
  return (
    <div>
      {" "}
      <div className="mt-4">
        <h3 className="text-xl font-semibold mb-2">Components:</h3>
        <div className="bg-white shadow-md rounded-lg p-4">
          <p>{components.join(", ")}</p>
        </div>
      </div>
    </div>
  );
};

export default Components;
