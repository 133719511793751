import { useEffect, useState } from "react";
import { ISpell, ISpellList } from "../interface/SpellListInterface";
import { fetchSpellsHandler } from "../services/fetchHandler";
import SpellCard from "./SpellCard";
import ErrorMessage from "./ErrorMessage";
import CircularLoading from "./CircularLoading";

type Props = {};

const SpellsList = (props: Props) => {
  const [spellList, setSpellList] = useState<ISpell[] | undefined>(undefined);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchSpellsHandler()
      .then((res: ISpellList) => setSpellList(res.results))
      .catch((error) => {
        console.log(error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <CircularLoading />;
  }

  if (error) {
    <ErrorMessage error={error} />;
  }

  return (
    <div className="flex flex-col px-8 py-4">
      <div className="flex flex-wrap gap-4 justify-center">
        {spellList &&
          spellList.map((spell: ISpell) => (
            <SpellCard key={spell.index} spell={spell} />
          ))}
      </div>
    </div>
  );
};

export default SpellsList;
