import React from "react";

type Props = {
  higher_level: string;
};

const HigherLevel = ({ higher_level }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Higher Level:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{higher_level}</p>
      </div>
    </div>
  );
};

export default HigherLevel;
