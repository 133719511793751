import axios from 'axios';
import endpoints from '../constants/endpoints';



const fetchSpellDetailHandler = async (index: string) => {
    try {
        const response = await axios.get(endpoints.spell(index));
        return response.data;
    } catch (error) {
        throw error
    }
};

export { fetchSpellDetailHandler };