import React from "react";

type Props = { isConcentration: boolean };

const Concentration = (isConcentration: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Concentration:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{isConcentration ? "Yes" : "No"}</p>
      </div>
    </div>
  );
};

export default Concentration;
