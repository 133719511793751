import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ISpellDetail } from "../interface/SpellInterface";
import { fetchSpellDetailHandler } from "../services/fetchSpellDetailHandler";
import CircularLoading from "./CircularLoading";
import ErrorMessage from "./ErrorMessage";
import Description from "./SpellSections/Description";
import HigherLevel from "./SpellSections/HigherLevel";
import Range from "./SpellSections/Range";
import Components from "./SpellSections/Components";
import Material from "./SpellSections/Material";
import Ritual from "./SpellSections/Ritual";
import Duration from "./SpellSections/Duration";
import Concentration from "./SpellSections/Concentration";
import CastingTime from "./SpellSections/CastingTime";
import Level from "./SpellSections/Level";
import AttackType from "./SpellSections/AttackType";
import School from "./SpellSections/School";
import Classes from "./SpellSections/Classes";
import SubClasses from "./SpellSections/SubClasses";
import Damage from "./SpellSections/Damage";

type Props = {};

const SpellDetail = (props: Props) => {
  const { index } = useParams<{ index: string }>();
  const [spell, setSpell] = useState<ISpellDetail | undefined>(undefined);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (index) {
      fetchSpellDetailHandler(index)
        .then((spell: ISpellDetail) => setSpell(spell))
        .catch((error: any) => {
          console.log(error);
          setError(error);
        })
        .finally(() => setLoading(false));
    }
  }, [index]);

  if (loading) {
    return <CircularLoading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className="flex flex-col px-8 py-4">
      <h2 className="text-2xl font-bold mb-4">{spell?.name}</h2>
      <div className="flex flex-wrap gap-4">
        {spell?.desc &&
          spell?.desc.length > 0 &&
          spell.desc.map((desc, index) => (
            <Description desc={desc} key={index} />
          ))}
      </div>

      {spell?.higher_level && spell?.higher_level.length > 0 && (
        <HigherLevel higher_level={spell.higher_level} />
      )}

      {spell?.range && <Range range={spell?.range} />}

      {spell?.components && spell?.components.length > 0 && (
        <Components components={spell?.components} />
      )}

      {spell?.material && <Material material={spell?.material} />}

      {spell && <Ritual isRitual={spell?.ritual ? spell?.ritual : false} />}

      {spell?.duration && <Duration duration={spell?.duration} />}

      {spell?.concentration && (
        <Concentration
          isConcentration={spell?.concentration ? spell?.concentration : false}
        />
      )}

      {spell?.casting_time && (
        <CastingTime casting_time={spell?.casting_time} />
      )}

      {spell?.level && <Level level={spell?.level} />}

      {spell?.attack_type && <AttackType attack_type={spell?.attack_type} />}

      {spell?.damage && <Damage damage={spell?.damage} />}

      {spell?.school && <School school={spell?.school} />}

      {spell?.classes && spell?.classes.length > 0 && (
        <Classes classes={spell?.classes} />
      )}

      {spell?.subclasses && spell?.subclasses.length > 0 && (
        <SubClasses subClasses={spell?.subclasses} />
      )}
    </div>
  );
};

export default SpellDetail;
