import React from "react";

type Props = { desc: string };

const Description = ({ desc }: Props) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <p>{desc}</p>
    </div>
  );
};

export default Description;
