import React from "react";

type Props = { level: number };

const Level = ({ level }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Level:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{level}</p>
      </div>
    </div>
  );
};

export default Level;
