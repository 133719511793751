import React from "react";

type Props = {
  casting_time: string;
};

const CastingTime = ({ casting_time }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Casting Time:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{casting_time}</p>
      </div>
    </div>
  );
};

export default CastingTime;
