import React from "react";

type Props = {
  material: string;
};

const Material = ({ material }: Props) => {
  return (
    <div className="mt-4">
      <h3 className="text-xl font-semibold mb-2">Material:</h3>
      <div className="bg-white shadow-md rounded-lg p-4">
        <p>{material}</p>
      </div>
    </div>
  );
};

export default Material;
